// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {GXvV1DcyZ: {hover: true}};

const cycleOrder = ["GXvV1DcyZ"];

const variantClassNames = {GXvV1DcyZ: "framer-v-g57bv1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tap, title, width, ...props}) => { return {...props, qGfxT4v26: title ?? props.qGfxT4v26 ?? "Send It Here", wbEMx7h4O: tap ?? props.wbEMx7h4O} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wbEMx7h4O, qGfxT4v26, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "GXvV1DcyZ", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1yoihl2 = activeVariantCallback(async (...args) => {
if (wbEMx7h4O) {
const res = await wbEMx7h4O(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PyXuH", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "pgD_sCojZ"}} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-g57bv1", className)} framer-1yb03mz`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"GXvV1DcyZ"} onTap={onTap1yoihl2} ref={ref} style={{background: "linear-gradient(102deg, #ff5e2c 0%, #f22161 100%)", borderBottomLeftRadius: 166.98, borderBottomRightRadius: 166.98, borderTopLeftRadius: 166.98, borderTopRightRadius: 166.98, ...style}} variants={{"GXvV1DcyZ-hover": {background: "linear-gradient(165deg, hsl(14, 100%, 52%) 0%, hsl(342, 100%, 54%) 100%)"}}} {...addPropertyOverrides({"GXvV1DcyZ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO09idmlvdXNseSBSZWd1bGFy", "--framer-font-family": "\"Obviously Regular\", sans-serif", "--framer-font-size": "20px", "--framer-letter-spacing": "-1px", "--framer-line-height": "100%", "--framer-text-alignment": "right", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Send It Here</motion.p></React.Fragment>} className={"framer-w2bq40"} data-framer-name={"Send It Here"} fonts={["CUSTOM;Obviously Regular"]} layoutDependency={layoutDependency} layoutId={"uv2UmT8AF"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={qGfxT4v26} verticalAlignment={"center"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PyXuH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PyXuH .framer-1yb03mz { display: block; }", ".framer-PyXuH .framer-g57bv1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: visible; padding: 16px 100px 20px 100px; position: relative; text-decoration: none; width: min-content; }", ".framer-PyXuH .framer-w2bq40 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-PyXuH .framer-v-g57bv1 .framer-g57bv1 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PyXuH .framer-g57bv1 { gap: 0px; } .framer-PyXuH .framer-g57bv1 > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-PyXuH .framer-g57bv1 > :first-child { margin-left: 0px; } .framer-PyXuH .framer-g57bv1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 329.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Wm2whzDS_":{"layout":["auto","auto"]}}}
 * @framerVariables {"wbEMx7h4O":"tap","qGfxT4v26":"title"}
 */
const FrameruX1G1Q7_J: React.ComponentType<Props> = withCSS(Component, css, "framer-PyXuH") as typeof Component;
export default FrameruX1G1Q7_J;

FrameruX1G1Q7_J.displayName = "BTN-Primary";

FrameruX1G1Q7_J.defaultProps = {height: 56, width: 329.5};

addPropertyControls(FrameruX1G1Q7_J, {wbEMx7h4O: {title: "Tap", type: ControlType.EventHandler}, qGfxT4v26: {defaultValue: "Send It Here", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameruX1G1Q7_J, [{family: "Obviously Regular", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/uX1G1Q7_J:default", url: "assets/QXCC6HITqYj9vcduhtsg1xYfvA.woff"}, url: new URL("assets/QXCC6HITqYj9vcduhtsg1xYfvA.woff", import.meta.url).href}])